<template>
  <div class="ui-form-input">
    <label
      v-tooltip="tooltip"
      :for="attrs.id"
      class="ui-form-input__label"
      v-text="`${$translate(label)}:`"
    ></label>
    <div class="ui-form-input__input-wrapper">
      <input v-bind="attrs" class="ui-form-input__input" v-on="listeners">
    </div>
  </div>
</template>
<script>
import { AppEvent } from 'redge-media-web-ui';

export default {
  inheritAttrs: false,
  props: {
    label: String,
    tooltip: [String, Object],
  },
  computed: {
    attrs() {
      const { id, type, ...attrs } = this.$attrs;

      return {
        ...attrs,
        // eslint-disable-next-line no-underscore-dangle
        id: id || `input-${this._uid}`,
        type: type || 'text',
      };
    },
    listeners() {
      const { input, ...listeners } = this.$listeners;

      return {
        ...listeners,
        input: (event) => this.$emit(AppEvent.INPUT, event.target.value),
      };
    },
  },
};
</script>
