export const wrapValueArray = (value) => (Array.isArray(value) ? value : [value]);
export const unwrapValueArray = (value) => (Array.isArray(value) ? value[0] : value);

export const getActiveFilters = (queryParams, filters) => {
  const filtersFields = filters.map(({ field }) => field);
  return Object.entries(queryParams).reduce(
    (acc, [key, value]) => ({
      ...acc,
      ...(filtersFields.includes(key) ? { [key]: wrapValueArray(value) } : {}),
    }),
    {},
  );
};

export const getEmptyNotRequiredActiveFilters = (activeFilters, filters) => Object.keys(activeFilters)
  .filter((key) => filters.find((filter) => filter.field === key && !filter.required))
  .reduce((acc, key) => ({ ...acc, [key]: [] }), {});

export const checkIfFilterSettingMatchesFilters = ({ filters }, activeFilters) => Object.keys(filters).length === Object.keys(activeFilters).length
  && Object.keys(filters).every((key) => {
    const filtersValuesAsStrings = filters[key] ? filters[key].map((value) => value.toString()) : [];
    const activeFiltersValuesAsStrings = activeFilters[key] ? activeFilters[key].map((value) => value.toString()) : [];

    return (
      filtersValuesAsStrings.length === activeFiltersValuesAsStrings.length
      && filtersValuesAsStrings.every((value) => activeFiltersValuesAsStrings.includes(value))
    );
  });

export const getCurrentFilterSetting = (filterId, availableFiltersSettings = []) => availableFiltersSettings.find(({ id }) => id === filterId);

export const getAvailableFilterName = (currentRoute) => {
  const matchedPathParts = currentRoute.matched[0].path.replace('/gui/', '').split('/');
  const mappedMatchPathParts = matchedPathParts.map((part) => {
    if (part === ':id') {
      return 'id';
    }

    if (part.includes(':')) {
      const paramName = part.replace(':', '').replace('?', '');
      return currentRoute.params[paramName];
    }

    return part;
  });

  // filter has been added as fix for urls with optional $route.params e.g. :page?
  return mappedMatchPathParts.filter((part) => part !== undefined).join('-');
};
