<template>
  <div class="app" :class="{ 'app--with-header': operator.isLogged }">
    <app-header v-if="operator.isLogged" />
    <div class="app__content">
      <div v-if="isOperatorLogged && errorInfo" class="app__info">
        <p class="app__info-paragraph" v-text="errorInfo"></p>
      </div>
      <template v-else>
        <app-menu v-if="isOperatorLogged" />
        <router-view class="app__view" :key="currentTenantUuid" />
      </template>
    </div>
  </div>
</template>
<script>
import Vue from 'vue';
import 'core-js/stable';
import { UiErrorHandlerPlugin, UiI18NPlugin, UiModalPlugin, dateFormat, tooltip } from 'redge-media-web-ui';
import 'regenerator-runtime/runtime';
import { storeMutations } from '@/application/app-store';
import eventBus from '@/application/event-bus';
import http from '@/application/http';
import operator from '@/application/operator';
import { i18nConfig } from '@/i18n';
import router from '@/router';
import { redirectToRouteWithTenant } from '@/router/utils';
import { AppHeader, AppMenu } from '@/components';

Vue.config.productionTip = false;
Vue.directive('tooltip', tooltip());
Vue.prototype.$dateFormat = dateFormat;
Vue.prototype.$http = http;
Vue.use(UiErrorHandlerPlugin, { eventBus });
Vue.use(UiI18NPlugin, i18nConfig);
Vue.use(UiModalPlugin);

// TODO Use from WEB_UI, when it will be available
Vue.prototype.$loader = {
  show() {},
  hide() {},
};

export default Vue.extend({
  router,
  components: {
    AppHeader,
    AppMenu,
  },
  data() {
    return {
      operator,
    };
  },
  computed: {
    currentTenantUuid() {
      return operator.selectedTenant?.uuid;
    },
    errorInfo() {
      if (!operator.tenants.length) {
        return this.$translate('COMMON_NO_TENANTS_INFO');
      }

      if (operator.hasNoAccess) {
        return this.$translate('COMMON_NO_TENANT_INFO');
      }

      if (!operator.roles.length) {
        return this.$translate('COMMON_NO_ROLES_INFO');
      }

      if (!operator.availableSections.length) {
        return this.$translate('COMMON_NO_VALID_ROLE_INFO');
      }
    },
    hasRouteTenantName() {
      return (
        this.$route.name !== 'login' &&
        operator.selectedTenant?.name &&
        !operator.hasNoAccess &&
        !this.$route.path.includes(operator.selectedTenant?.name)
      );
    },
    isOperatorLogged() {
      return operator.isLogged;
    },
  },
  mounted() {
    storeMutations.setIsAppMounted(true);

    if (this.hasRouteTenantName) {
      redirectToRouteWithTenant(true);
    }
  },
});
</script>
<style lang="scss">
@import '~@/styles/main';
</style>
