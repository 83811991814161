<template>
  <ui-list v-bind="uiListProps" />
</template>
<script>
import { UiTableDetailLink } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import operator from '@/application/operator';
import { QueryParam } from '@/enums';
import { RoleMixin } from '@/mixins';
import { ADMIN } from '@/enums/role-name';
import { UiList } from '@/components';
import * as Column from '@/pages/column';
import * as Field from '@/pages/field';

export default {
  components: {
    UiList,
  },
  mixins: [RoleMixin],
  data() {
    return {
      writeRole: ADMIN,
    };
  },
  computed: {
    uiListProps() {
      return {
        recordsUrl: Urls.OPERATORS,
        recordAddFormOptions: this.isAllowed && {
          modalTitleFunction: () => 'COMMON_ADD_OPERATOR',
          formElementsFunction: () => [[Field.EMAIL(false), Field.ROLES]],
        },
        recordDeleteUrlFunction: Urls.OPERATORS_ID,
        routeQuery: { sort: `email,${QueryParam.ASCENDING}` },
        columnsFunction: (removeRecordFunction) => [
          {
            field: 'email',
            minWidth: 280,
            isSortable: true,
            headingLabel: 'COMMON_EMAIL',
            dataComponent: UiTableDetailLink,
            dataComponentPropsMapper: ({ id, email }) => ({
              name: 'operators.detail',
              params: { id, page: 'metadata' },
              text: email,
            }),
          },
          Column.CREATED_AT,
          Column.LAST_LOGIN_DATE,
          Column.ACTIONS_OPERATOR(this.isAllowed, removeRecordFunction, operator.id),
        ],
        paramsConfig: {
          sort: `email,${QueryParam.ASCENDING}`,
        },
      };
    },
  },
};
</script>
