<template>
  <app-header-base
    v-bind="{
      appData,
      i18n: $i18n,
      info,
      logoutUrl,
      operator,
    }"
    @change-tenant="changeTenant"
  />
</template>
<script>
import { AppHeader as AppHeaderBase } from 'redge-media-web-ui';
import info from '@/application/info';
import operator from '@/application/operator';
import Urls from '@/application/urls';
import { LANGUAGE_SETTING } from '@/i18n';
import { redirectToRouteWithTenant } from '@/router/utils';

export default {
  components: {
    AppHeaderBase,
  },
  data() {
    return {
      info,
      operator,
    };
  },
  computed: {
    appData() {
      return {
        contentLanguageKey: LANGUAGE_SETTING,
        environment: process.env.VUE_APP_ENV,
        logo: { alt: 'Redge Media Chat', src: '/logo.svg' },
      };
    },
    hasRouteTenantName() {
      return this.$route.path.includes(operator.selectedTenant.name);
    },
    logoutUrl() {
      return `${this.$http.defaults.baseURL}${Urls.LOGOUT}`;
    },
  },
  mounted() {
    window.addEventListener('popstate', this.onPopState);
  },
  beforeDestroy() {
    window.removeEventListener('popstate', this.onPopState);
  },
  methods: {
    async changeTenant(tenant) {
      await operator.switchTenant(tenant);
      redirectToRouteWithTenant();
    },
    async onPopState() {
      if (!this.hasRouteTenantName) {
        await operator.onPopState();
        redirectToRouteWithTenant();
      }
    },
  },
};
</script>
