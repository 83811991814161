<template>
  <div
    :class="{ 'ui-chat-message-avatar--vip': user.vip }"
    :style="avatarStyle"
    class="ui-chat-message-avatar"
    v-text="avatarLetter"
  ></div>
</template>
<script>
export default {
  props: {
    user: Object,
  },
  computed: {
    avatarUrl() {
      return this.user.avatarUrl;
    },
    avatarStyle() {
      return {
        'background-color': `${this.user.profileColor}`,
        'background-image': this.avatarUrl && `url(${this.avatarUrl})`,
      };
    },
    avatarLetter() {
      if (this.avatarUrl) {
        return '';
      }

      return this.user.username?.charAt(0).toLocaleUpperCase() || '?';
    },
  },
};
</script>
