import Vue from 'vue';

const defaultStoreData = {
  isAppMounted: false,
};

export const store = Vue.observable({ ...defaultStoreData });

export const storeGetters = {
  isAppMounted: () => store.isAppMounted,
};

export const storeMutations = {
  setIsAppMounted(isAppMounted) {
    store.isAppMounted = isAppMounted;
  },
};
