<template>
  <section class="ui-metadata">
    <ui-form v-if="model" v-bind="uiFormProps" class="ui-metadata__form" />
  </section>
</template>
<script>
import { updateDoc } from 'firebase/firestore';
import { UiForm } from 'redge-media-web-ui';
import * as Fields from '@/components/ui-form/fields';
import * as Field from '@/pages/field';

export default {
  components: {
    UiForm,
  },
  props: {
    document: Object,
    documentRef: Object,
  },
  data() {
    return {
      model: undefined,
      modelCopy: undefined,
    };
  },
  computed: {
    hasModelChanged() {
      return JSON.stringify(this.model) !== JSON.stringify(this.modelCopy);
    },
    uiFormProps() {
      return {
        value: this.model,
        elements: [
          [
            Field.textInputField('name', 'CHAT_THREAD_NAME', false, true),
            Fields.checkboxField('allowComments', 'CHAT_THREAD_ALLOW_COMMENTS'),
            Fields.checkboxField('shouldModerate', 'CHAT_THREAD_SHOULD_MODERATE'),
            Fields.numberInputField('threshold', 'CHAT_THREAD_THRESHOLD', true),
            Fields.numberInputField('historyReadLimit', 'CHAT_THREAD_HISTORY_READ_LIMIT', true),
          ],
        ],
        buttons: [
          {
            onClick: this.saveModel,
            label: 'COMMON_SAVE',
            type: 'submit',
            class: 'button button--save',
            tabindex: '0',
            disabled: !this.hasModelChanged,
          },
        ],
      };
    },
  },
  watch: {
    document: {
      handler() {
        this.model = { ...this.document };
        this.modelCopy = { ...this.document };
      },
      immediate: true,
    },
  },
  methods: {
    async saveModel() {
      const {
        allowComments, historyReadLimit, shouldModerate, threshold,
      } = this.model;
      await updateDoc(this.documentRef, {
        allowComments,
        historyReadLimit: historyReadLimit || 10,
        shouldModerate,
        threshold,
      });

      this.modelCopy = { ...this.model };
    },
  },
};
</script>
