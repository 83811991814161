import { ACTIONS, BUTTON_UNBLOCK_USER } from '@/components/ui-table/columns';
import { BLOCKED_AT } from '@/pages/column';

export const BLOCKED_USERS_ACTIONS = (isAllowed, unblockRecordMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_UNBLOCK_USER(() => unblockRecordMethod(record))],
  });

  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export { BLOCKED_AT };
