var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"ui-chat-message"},[_c('div',{staticClass:"ui-chat-message__row"},[(_vm.user)?_c('ui-chat-message-avatar',{attrs:{"user":_vm.user}}):_vm._e(),(_vm.user.vip)?_c('span',{staticClass:"ui-chat-message__vip",domProps:{"textContent":_vm._s('VIP')}}):_vm._e(),_c('div',{staticClass:"ui-chat-message__nickname",class:{
        'ui-chat-message__nickname--blocked': _vm.message.isUserBlocked,
        'ui-chat-message__nickname--banned': _vm.message.isUserShadowBanned,
      },domProps:{"textContent":_vm._s(_vm.user.nickname)}}),_c('div',{staticClass:"ui-chat-message__user-actions"},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.userBlockTooltipText),expression:"userBlockTooltipText"}],staticClass:"icon-user ui-chat-message__icon ui-chat-message__icon-user-block",class:{ 'ui-chat-message__icon-user-block--blocked': _vm.message.isUserBlocked },on:{"click":function($event){_vm.message.isUserBlocked ? _vm.unblockUser(_vm.user) : _vm.blockUser(_vm.user)}}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.userShadowBanTooltipText),expression:"userShadowBanTooltipText"}],staticClass:"icon-user ui-chat-message__icon ui-chat-message__icon-user-shadow-ban",class:{ 'ui-chat-message__icon-user-shadow-ban--banned': _vm.message.isUserShadowBanned },on:{"click":function($event){_vm.message.isUserShadowBanned ? _vm.unShadowBanUser(_vm.user) : _vm.shadowBanUser(_vm.user)}}})])],1),_c('div',{staticClass:"ui-chat-message__data",class:{
      'ui-chat-message__data--should-be-moderated': _vm.message.shouldBeModerated,
      'ui-chat-message__data--deleted': _vm.message.deletedAt > 0,
      'ui-chat-message__data--shadow-banned': _vm.message.verified < 0,
    }},[_c('div',{staticClass:"ui-chat-message__data-info"},[_c('div',{staticClass:"ui-chat-message__created-at",domProps:{"textContent":_vm._s(_vm.createdAt)}}),_c('div',{staticClass:"ui-chat-message__message-actions"},[(_vm.message.shouldBeModerated || _vm.message.verified < 0 || !_vm.message.verified)?_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$translate('HELP_ACCEPT')),expression:"$translate('HELP_ACCEPT')"}],staticClass:"ui-chat-message__icon icon-eye",on:{"click":function($event){return _vm.acceptMessage(_vm.message)}}}):_vm._e(),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.messageBlockTooltipText),expression:"messageBlockTooltipText"}],staticClass:"icon-trash ui-chat-message__icon ui-chat-message__icon-message-block",class:[_vm.message.deletedAt < 0 ? 'icon-trash' : 'icon-unlocked'],on:{"click":function($event){_vm.message.deletedAt < 0 ? _vm.blockMessage(_vm.message) : _vm.unBlockMessage(_vm.message)}}}),_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.$translate('HELP_REMOVE_PERMANENTLY')),expression:"$translate('HELP_REMOVE_PERMANENTLY')"}],staticClass:"icon-trash ui-chat-message__icon ui-chat-message__icon-message-delete",on:{"click":function($event){return _vm.deleteMessage(_vm.message)}}})]),_c('div',{staticClass:"ui-chat-message__id",domProps:{"textContent":_vm._s(`ID: ${_vm.message.id}`)}})]),_c('div',{staticClass:"ui-chat-message__text",domProps:{"innerHTML":_vm._s(_vm.messageText)}})]),_vm._t("replies")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }