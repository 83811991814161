<template>
  <div v-click-outside="hide" class="ui-filters" :class="{ 'ui-filters--is-visible': filterMenuActive }">
    <div class="ui-filters__header">
      <h3 v-text="$translate('FILTER_LABEL')"></h3>
      <span class="ui-filters__header-clear" @click="clearActiveFilters" v-text="$translate('FILTER_CLEAR')"></span>
      <i class="ui-filters__header-close icon-close" @click="hide"></i>
    </div>

    <div class="ui-filters__list">
      <component
        :is="component"
        v-for="{ field, component, componentOptions } in filters"
        :key="field"
        :values="activeFilters[field]"
        :modified-not-saved="modifiedNotSavedFilters.includes(field)"
        v-bind="componentOptions"
        @change-modified-not-saved="(value) => changeFilterModifiedNotSaved(field, value)"
        @change="(values) => changeActiveFilter(field, values)"
      />
    </div>
  </div>
</template>
<script>
import { clickOutside } from 'redge-media-web-ui';
import { getEmptyNotRequiredActiveFilters } from '@/utils/settings-filters';
import { resetStore, storeGetters, storeMutations } from './store';

export default {
  directives: {
    clickOutside,
  },
  props: {
    activeFilters: {
      type: Object,
      required: true,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {};
  },
  computed: {
    ...storeGetters,
  },
  created() {
    resetStore();
  },
  methods: {
    ...storeMutations,
    hide() {
      this.setFilterMenuActive(false);
    },
    changeFilterModifiedNotSaved(field, value) {
      if (value && !this.modifiedNotSavedFilters.includes(field)) {
        this.setModifiedNotSavedFilters([...this.modifiedNotSavedFilters, field]);
      } else if (!value) {
        this.setModifiedNotSavedFilters(this.modifiedNotSavedFilters.filter((f) => f !== field));
      }
    },
    updateQueryParams(queryParams) {
      this.$emit('update-query-params', queryParams);
    },
    clearActiveFilters() {
      this.updateQueryParams(getEmptyNotRequiredActiveFilters(this.activeFilters, this.filters));
    },
    changeActiveFilter(field, values) {
      this.updateQueryParams({ [field]: values });
    },
  },
};
</script>
