<template>
  <div class="ui-list" :class="{ 'ui-list--is-loading': isLoading }">
    <div class="ui-list__nav">
      <button
        v-tooltip="$translate('COMMON_ADD')"
        class="button"
        type="button"
        @click.prevent="createAddAndEditRecordModal({})"
      >
        <span class="icon-add"></span>
      </button>
    </div>
    <ui-table v-bind="uiTableProps" />
  </div>
</template>
<script>
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  getDocs,
  onSnapshot,
  serverTimestamp,
  updateDoc,
} from 'firebase/firestore';

import { AppEvent, UiTable } from 'redge-media-web-ui';
import operator from '@/application/operator';
import { getFirestoreDb } from '@/application/firestore';
import { parseRegexp } from '@/utils';
import { ADMIN } from '@/enums/role-name';
import { UiModalSaveModel } from '@/components';
import { RoleMixin } from '@/mixins';
import * as Field from '@/pages/field';
import * as Column from './columns';

export default {
  components: {
    UiTable,
  },
  mixins: [RoleMixin],
  data() {
    return {
      db: undefined,
      documentRef: undefined,
      isLoading: false,
      records: [],
      writeRole: ADMIN,
    };
  },
  computed: {
    tenantUuid() {
      return operator.selectedTenant.uuid;
    },
    sortedRecords() {
      return [...this.records].sort((a, b) => a.value.localeCompare(b.value));
    },
    uiTableProps() {
      return {
        records: this.sortedRecords.map((record) => ({ ...record, ...parseRegexp(record.value) })),
        columns: [
          {
            field: 'pattern',
            headingLabel: 'COMMON_FORBIDDEN_WORDS',
            minWidth: 150,
          },
          Column.CHECKBOX('isCaseSensitive', 'COMMON_CASE_SENSITIVE'),
          Column.FORBIDDEN_WORDS_ACTIONS(this.isAllowed, this.removeRecord, this.createAddAndEditRecordModal),
        ],
      };
    },
  },
  async mounted() {
    this.isLoading = true;
    this.db = await getFirestoreDb().catch(console.error);
    this.documentRef = doc(this.db, `tenants/${this.tenantUuid}`);

    this.load();
  },
  beforeDestroy() {
    this.unsubscribeForbiddenWords?.();
  },
  methods: {
    async load() {
      this.forbiddenWordsCollection = collection(this.db, `tenants/${this.tenantUuid}/forbiddenWords`);

      await getDocs(this.forbiddenWordsCollection).catch(console.error);
      this.unsubscribeForbiddenWords = onSnapshot(this.forbiddenWordsCollection, this.parseAndUpdateRecords);
    },
    async removeRecord({ docRef }) {
      await deleteDoc(docRef);
      await this.updateDocument();
    },
    updateDocument() {
      return updateDoc(this.documentRef, {
        forbiddenWordsModifiedAt: serverTimestamp(),
      });
    },
    parseAndUpdateRecords(snapshot) {
      this.records = snapshot.docs.map((document) => ({
        id: document.id,
        docRef: document.ref,
        ...document.data(),
      }));

      this.isLoading = false;
    },
    createAddAndEditRecordModal(model = {}) {
      const modal = this.$modal.create(UiModalSaveModel, {
        defaultValues: model,
        modalTitleFunction: () => (model.id ? 'COMMON_EDIT' : 'COMMON_ADD'),
        formElementsFunction: () => [[Field.regexpField('value', 'COMMON_FORBIDDEN_WORD')]],
      });

      modal.$on(AppEvent.SUCCESS, async ({ docRef, id, value }) => {
        const isRecordExists = this.records.some((record) => record.value === value);
        const data = { value };

        if (!value) {
          return;
        }

        if (id) {
          await updateDoc(docRef, data);
        } else {
          if (isRecordExists) {
            modal.error = [{ field: 'value', code: 'COMMON_FORBIDDEN_WORD_EXITS' }];
            return;
          }

          await addDoc(this.forbiddenWordsCollection, data);
        }

        await this.updateDocument();
        modal.close();
      });
    },
  },
};
</script>
