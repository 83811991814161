<template>
  <div>
    <div class="ui-list__nav">
      <ui-search-input class="ui-list__search-input" :value="keyword" @input="filterRecords" />
    </div>
    <ui-table v-bind="uiTableProps" />
  </div>
</template>
<script>
import { collection, endAt, getDocs, limit, onSnapshot, orderBy, query, startAt } from 'firebase/firestore';
import { UiSearchInput, UiTable } from 'redge-media-web-ui';
import operator from '@/application/operator';
import { getFirestoreDb } from '@/application/firestore';
import { ADMIN } from '@/enums/role-name';
import { RoleMixin } from '@/mixins';
import * as Column from './columns';

const THREAD_READ_LIMIT = 100;
const SEARCH_BY = 'itemTitle';

export default {
  components: {
    UiSearchInput,
    UiTable,
  },
  mixins: [RoleMixin],
  data() {
    return {
      db: undefined,
      records: [],
      keyword: '',
      writeRole: ADMIN,
    };
  },
  computed: {
    uiTableProps() {
      return {
        records: this.records,
        columns: [
          Column.CHAT_THREAD_TITLE,
          {
            field: 'name',
            headingLabel: 'CHAT_THREAD_NAME',
            width: 230,
          },
          Column.CHECKBOX('active', 'CHAT_THREAD_ACTIVE'),
          Column.CHECKBOX('allowComments', 'CHAT_THREAD_ALLOW_COMMENTS'),
          Column.CHECKBOX('shouldModerate', 'CHAT_THREAD_SHOULD_MODERATE'),
          {
            field: 'threshold',
            headingLabel: 'CHAT_THREAD_THRESHOLD',
            width: 150,
          },
          {
            field: 'historyReadLimit',
            headingLabel: 'CHAT_THREAD_HISTORY_READ_LIMIT',
            width: 150,
          },
        ],
        rowClassModifierFunction: ({ active }) => ({
          'ui-table-row--inactive': !active,
        }),
        scrollTopOnRecordsChange: false,
      };
    },
  },
  async mounted() {
    this.db = await getFirestoreDb().catch(console.error);
    this.load();
  },
  methods: {
    async load() {
      this.unsubscribeMessages?.();

      const chatsQuery = query(
        collection(this.db, `tenants/${operator.selectedTenant.uuid}/chats`),
        orderBy(SEARCH_BY),
        startAt(this.keyword),
        endAt(`${this.keyword}\uf8ff'`),
        limit(THREAD_READ_LIMIT),
      );

      await getDocs(chatsQuery).then(this.updateRecords).catch(console.error);
      this.unsubscribeMessages = onSnapshot(chatsQuery, this.updateRecords);
    },
    filterRecords(keyword) {
      this.keyword = keyword?.trim() || '';
      this.load();
    },
    updateRecords(snapshot) {
      this.records = snapshot.docs.map((document) => ({
        docRef: document.ref,
        ...document.data(),
      }));
    },
  },
};
</script>
