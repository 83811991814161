<template>
  <ui-checkbox :value="value" disabled />
</template>
<script>
import { UiCheckbox } from 'redge-media-web-ui';

export default {
  components: {
    UiCheckbox,
  },
  props: {
    value: Boolean,
  },
};
</script>
