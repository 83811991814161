import Vue from 'vue';
import en from '@/i18n/en.json';
import pl from '@/i18n/pl.json';

export const LANGUAGE_SETTING = 'redge-media-chat-lang';

export const i18nConfig = {
  fallbackLanguage: 'en',
  languageKey: LANGUAGE_SETTING,
  languages: ['en', 'pl'],
  translations: { en, pl },
};

const { $i18n } = Vue.prototype;

export default $i18n;
