import Vue from 'vue';
import VueRouter from 'vue-router';
import { AppEvent } from 'redge-media-web-ui';
import { ACCESS_DENIED, AUTHENTICATION_REQUIRED } from '@/application/constants';
import EventBus from '@/application/event-bus';
import operator from '@/application/operator';
import routes from '@/router/routes';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  if (operator.selectedTenant?.name && to.params.tenantName !== operator.selectedTenant.name) {
    to.params.tenantName = operator.selectedTenant.name;
  }

  if (to.query.tenant) {
    const tenant = operator.tenants?.find(({ uuid }) => uuid === to.query.tenant);

    if (tenant) {
      await operator.switchTenant(tenant);
    }

    delete to.query.tenant;

    return next(to);
  }

  if (router.app?.$modal) {
    router.app.$modal.close();
  }

  if (to.meta.authenticationRequired !== false && !operator.isLogged) {
    return next({ name: 'login', query: { request_uri: encodeURIComponent(window.location.href) } });
  }

  if (to.meta.authenticationRequired === false && operator.isLogged) {
    return next({ name: 'home' });
  }

  return next();
});

EventBus.on(AUTHENTICATION_REQUIRED, () => {
  const redirect = () => {
    operator.isLogged = false;

    router.push({
      name: 'login',
      query: { ...router.currentRoute.query },
      params: { next: router.currentRoute.fullPath },
    });
  };

  if (operator.isLogged) {
    router.app.$errorHandler(
      { code: 'COMMON_OPERATOR_LOGOUT_INFO' },
      { hideAfter: 10000, overlay: true, on: { event: AppEvent.CLOSE, callback: redirect } },
    );
  } else if (router.currentRoute.name !== 'login') {
    redirect();
  }
});

EventBus.on(ACCESS_DENIED, () => {
  const [availableSection] = operator.availableSections;

  if (availableSection) {
    const [item] = availableSection.items;
    router.push({ name: item.routeName });
  }
});

export default router;
