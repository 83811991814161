<template>
  <ui-form-dictionary-fetcher v-slot="slotOptions" v-bind="uiFormDictionaryFetcherOptions">
    <ui-multiple-choice-base v-bind="{ ...uiMultipleChoiceBaseOptions, ...slotOptions }" v-on="$listeners" />
  </ui-form-dictionary-fetcher>
</template>
<script>
import { UiMultipleChoiceBase } from 'redge-media-web-ui';
import { filterObjectEntries } from '@/utils';
import UiFormDictionaryFetcher from './ui-form-dictionary-fetcher.vue';

export default {
  components: {
    UiFormDictionaryFetcher,
    UiMultipleChoiceBase,
  },
  computed: {
    uiFormDictionaryFetcherOptions() {
      return filterObjectEntries(this.$attrs, Object.keys(UiFormDictionaryFetcher.props));
    },
    uiMultipleChoiceBaseOptions() {
      return filterObjectEntries(this.$attrs, Object.keys(UiMultipleChoiceBase.props));
    },
  },
};
</script>
