<template>
  <div class="ui-list" :class="{ 'ui-list--is-loading': isLoading }">
    <div class="ui-list__nav">
      <ui-search-input
        v-if="isSearchVisible"
        :value="$route.query.keyword"
        class="ui-list__search-input"
        @input="updateRouteQuery({ keyword: $event, page: 0 })"
      />
      <ui-paginator
        v-if="listData && listData.pagination"
        :first-result="listData.pagination.page * listData.pagination.size"
        :max-results="listData.pagination.size"
        :total-count="listData.totalCount"
        @change="firstResultChanged"
      />
      <div class="ui-list__nav-buttons">
        <button
          v-if="recordAddFormOptions"
          v-tooltip="$translate('COMMON_ADD')"
          class="button"
          type="button"
          @click.prevent="addRecord"
        >
          <span class="icon-add"></span>
        </button>
        <button
          v-if="filters"
          v-tooltip="$translate('COMMON_FILTERS')"
          class="button"
          type="button"
          @click.prevent.stop="setFilterMenuActive(!filterMenuActive)"
        >
          <span class="icon-sidebar-open"></span>
        </button>
      </div>
    </div>
    <ui-table
      :records="records"
      :columns="columnsFunction(removeRecord)"
      :row-class-modifier-function="rowClassModifierFunction"
      :sort-field="sortField"
      :sort-order="sortOrder"
      @sort="sortTable"
    >
      <template v-for="(_, slot) of $scopedSlots" #[slot]="scope">
        <slot :name="slot" v-bind="scope"></slot>
      </template>
    </ui-table>
    <ui-filters
      v-if="filters"
      :filters="filters"
      :active-filters="activeFilters"
      @update-query-params="updateQueryParams"
    />
  </div>
</template>
<script>
import {
  AppEvent, UiModalConfirm, UiModalPopup, UiPaginator, UiSearchInput, UiTable,
} from 'redge-media-web-ui';
import { QueryParam } from '@/enums';
import { getActiveFilters, unwrapValueArray, wrapValueArray } from '@/utils/settings-filters';
import { storeGetters, storeMutations } from '@/components/ui-filters/store';
import UiFilters from '@/components/ui-filters/index.vue';
import UiModalSaveModel from '@/components/ui-modal/ui-modal-save-model.vue';

const DEFAULT_PAGINATION_QUERY = {
  page: 0,
  size: 20,
};

const SINGLE_VALUE_ROUTE_PARAMS = [
  'sort',
  'order',
  'keyword',
  'lang',
  'page',
  'since',
  'size',
  'till',
  'active',
  'createdAtSince',
  'createdAtTill',
  'lastLoggedAtSince',
  'lastLoggedAtTill',
];

const routeQueryListToObject = (acc, [key, value]) => ({
  ...acc,
  [key]: SINGLE_VALUE_ROUTE_PARAMS.includes(key) ? unwrapValueArray(value) : wrapValueArray(value),
});

export default {
  components: {
    UiFilters,
    UiPaginator,
    UiSearchInput,
    UiTable,
  },
  props: {
    isSearchVisible: {
      type: Boolean,
      default: true,
    },
    recordsUrl: {
      type: String,
      required: true,
    },
    filters: Array,
    recordAddMapper: {
      type: Function,
      default: (model) => model,
    },
    recordAddFormOptions: [Object, Boolean],
    recordDeleteUrlFunction: Function,
    columnsFunction: {
      type: Function,
      required: true,
    },
    sortQuery: {
      type: Object,
      default: () => ({}),
    },
    paramsConfig: {
      type: Object,
      default: () => ({}),
    },
    rowClassModifierFunction: {
      type: Function,
      default: ({ active }) => (active ? '' : 'ui-table-row--inactive'),
    },
  },
  data() {
    return {
      isFiltersVisible: false,
      isLoading: false,
      isSaving: false,
      listData: null,
    };
  },
  computed: {
    ...storeGetters,
    sortField() {
      return this.$route.query.sort?.split(',')[0];
    },
    sortOrder() {
      return this.$route.query.sort?.split(',')[1];
    },
    records() {
      return this.listData?.elements || this.listData || [];
    },
    activeFilters() {
      return getActiveFilters(this.$route.query, this.filters);
    },
    routeQuery() {
      return Object.entries(this.$route.query).reduce(routeQueryListToObject, {});
    },
  },
  watch: {
    '$route.query': {
      handler() {
        if (Object.entries(this.$route.query).length === 0) {
          this.updateRouteQuery({ ...DEFAULT_PAGINATION_QUERY, ...this.sortQuery, ...this.paramsConfig });
        } else {
          const missingQueryParams = Object.entries(this.paramsConfig)
            .filter(([key]) => !Object.keys(this.$route.query).includes(key))
            .reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {});

          if (missingQueryParams && Object.keys(missingQueryParams).length !== 0) {
            this.updateRouteQuery(missingQueryParams);
          } else {
            this.load();
          }
        }
      },
      immediate: true,
    },
  },
  methods: {
    ...storeMutations,
    async load() {
      this.isLoading = true;

      try {
        this.listData = await this.$http
          .get(this.recordsUrl, { params: { ...this.routeQuery } })
          .then(({ data }) => data);

        this.isLoading = false;
      } catch (error) {
        this.isLoading = false;
        this.$errorHandler(error);
      }
    },
    updateRouteQuery(query) {
      this.$router.replace({
        path: this.$route.path,
        params: this.$route.params,
        query: { ...this.$route.query, ...query },
      });
    },
    changeActiveFilter(field, values) {
      this.updateQueryParams({ [field]: values });
    },
    updateQueryParams(query) {
      this.updateRouteQuery({ firstResult: 0, ...query });
    },
    firstResultChanged(_firstResult) {
      const page = Math.floor(_firstResult / this.$route.query.size);
      this.updateRouteQuery({ page });
    },
    sortTable(field) {
      this.updateRouteQuery({
        sort: `${field},${this.sortOrder === QueryParam.ASCENDING ? QueryParam.DESCENDING : QueryParam.ASCENDING}`,
      });
    },
    addRecord() {
      const modal = this.$modal.create(UiModalSaveModel, this.recordAddFormOptions);

      modal.$on(AppEvent.SUCCESS, async (model) => {
        if (this.isSaving) {
          return;
        }

        try {
          this.isSaving = true;
          await this.$http.post(this.recordsUrl, this.recordAddMapper(model));
          this.$modal.create(UiModalPopup, { text: this.$translate('COMMON_SAVE_SUCCESS') });
          this.isSaving = false;

          modal.close();
          await this.load();
        } catch (error) {
          this.isSaving = false;
          modal.error = error;
          this.$errorHandler(error);
        }
      });
    },
    removeRecord(record) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await this.$http.delete(this.recordDeleteUrlFunction(record.id));
        modal.close();
        this.listData.elements = this.listData.elements.filter(({ id }) => id !== record.id);
      });
    },
  },
};
</script>
