export default {
  INFO: '/local/info',
  LOGIN_SSO: '/local/login/authorization/sso',
  LOGOUT: '/local/logout',
  OPERATOR_FIREBASE_TOKEN: '/local/authorization/token',
  OPERATORS: '/local/operators',
  OPERATORS_DETAILS: '/local/operators/details',
  OPERATORS_ID: (id) => `/local/operators/${id}`,
  OPERATORS_ROLES: '/local/operators/roles',
};
