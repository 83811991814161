<template>
  <ui-metadata v-bind="{ ...uiMetadataProps, http }" v-on="$listeners" />
</template>
<script>
import { UiMetadata } from 'redge-media-web-ui';
import http from '@/application/http';
import Urls from '@/application/urls';
import operator from '@/application/operator';
import { RoleMixin } from '@/mixins';
import { ADMIN } from '@/enums/role-name';
import * as Field from '@/pages/field';

export default {
  components: {
    UiMetadata,
  },
  mixins: [RoleMixin],
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  data() {
    return {
      http,
      writeRole: ADMIN,
    };
  },
  computed: {
    uiMetadataProps() {
      return {
        isDisabled: !this.isAllowed || operator.id === parseInt(this.id, 10),
        modelUrl: Urls.OPERATORS_ID(this.id),
        formElementsFunction: () => [
          [
            Field.HEADER_BASIC_INFORMATION,
            Field.EMAIL(true),
            Field.ROLES,
            Field.textInputField('externalUuid', 'COMMON_EXTERNAL_UUID', false, true),
            Field.datePickerField('lastLoggedAt', 'COMMON_LAST_LOGIN_DATE', false, true),
          ],
        ],
      };
    },
  },
};
</script>
