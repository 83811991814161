import { UiTableActions } from 'redge-media-web-ui';
import { UiTableCheckbox } from '@/components';

export const ACTIONS = (isAllowed, dataComponentPropsMapper) => ({
  field: 'actions',
  width: 90,
  isHidden: !isAllowed,
  headingLabel: 'COMMON_ACTIONS',
  dataComponent: UiTableActions,
  dataComponentPropsMapper,
});

export const CHECKBOX = (field, headingLabel) => ({
  field,
  headingLabel,
  width: 140,
  dataComponent: UiTableCheckbox,
  dataComponentPropsMapper: (record) => ({
    value: !!record?.[field],
  }),
});

export const BUTTON_EDIT = (onClick) => ({
  tooltip: 'HELP_EDIT',
  icon: 'icon-edit',
  onClick,
});

export const BUTTON_UNBLOCK_USER = (onClick) => ({
  tooltip: 'HELP_UNBLOCK_USER',
  icon: 'icon-user',
  onClick,
});

export const BUTTON_REMOVE = (onClick) => ({
  tooltip: 'HELP_REMOVE',
  icon: 'icon-trash',
  onClick,
});
