import { NumberDivider } from 'redge-media-web-utils';
import { storeGetters } from '@/application/app-store';
import { TENANT_SETTING } from '@/application/constants';
import { resetFirebaseApp } from '@/application/firestore';
import http from '@/application/http';
import menuSections from '@/application/menu-sections';
import Urls from '@/application/urls';

const PROLONG_SESSION_INTERVAL = 15 * NumberDivider.MINUTE_TO_SECONDS; // [s]

class Operator {
  constructor() {
    this.availableSections = [];
    this.hasNoAccess = false;
    this.isLogged = false;
    this.roles = [];
    this.selectedTenant = null;
    this.serverTimeOffset = 0;
    this.sessionIntervalId = null;
    this.tenants = [];
  }

  getAvailableTenant(tenants, tenantName) {
    const isAppMounted = storeGetters.isAppMounted();
    const tenantUuid = localStorage.getItem(TENANT_SETTING);

    return tenants.find(({ name, uuid }) => (!isAppMounted && tenantName ? name === tenantName : uuid === tenantUuid));
  }

  getTenantNameFromPath() {
    const pathname = window.location.pathname.split('/')[1];

    return pathname.endsWith(',t') ? pathname.split(',')[0] : null;
  }

  async checkTenant() {
    if (!this.tenants.length) {
      localStorage.removeItem(TENANT_SETTING);
      return;
    }

    if (!this.selectedTenant?.uuid) {
      await this.switchTenant(this.tenants[0]);
      return;
    }

    const tenantName = this.getTenantNameFromPath();

    if (!tenantName && this.selectedTenant.uuid) {
      const tenant = this.tenants.find(({ uuid }) => uuid === this.selectedTenant.uuid);
      this.setTenant(tenant);
      return;
    }

    const availableTenant = this.getAvailableTenant(this.tenants, tenantName);

    if (!availableTenant) {
      this.onTenantMissing(tenantName);
      return;
    }

    this.setTenant(availableTenant);
  }

  getServerTime() {
    return Date.now() - this.serverTimeOffset;
  }

  async fetchToken() {
    return http
      .post(Urls.OPERATOR_FIREBASE_TOKEN, {})
      .then(({ data }) => data.token)
      .catch(console.error);
  }

  async fetch() {
    this.setHasNoAccess(false);

    const { data, headers } = await http.get(Urls.OPERATORS_DETAILS);

    this.onResponse(data, headers);
  }

  async onPopState() {
    const tenantName = this.getTenantNameFromPath();
    const tenant = this.tenants.find((t) => t.name === tenantName);

    if (!tenant) {
      this.onTenantMissing(tenantName);
      return;
    }

    if (tenant.name !== this.selectedTenant.name) {
      await this.switchTenant(tenant);
    }
  }

  async onResponse(data, headers) {
    Object.assign(this, data, { isLogged: true, tenants: data.tenants.map((t) => ({ ...t, name: t.uuid })) });

    await this.checkTenant();
    this.setServerTimeOffset(headers);
    this.setAvailableSections();
    this.prolongSessionPeriodically();
  }

  onTenantMissing(tenantName) {
    this.setTenant({ name: tenantName, uuid: '' });
    this.setHasNoAccess(true);
  }

  prolongSessionPeriodically() {
    const interval = PROLONG_SESSION_INTERVAL * 1000;

    clearInterval(this.sessionIntervalId);

    this.sessionIntervalId = setInterval(() => http.get(Urls.OPERATORS_DETAILS).catch(location.reload), interval);
  }

  setAvailableSections() {
    const roles = this.roles.map(({ name }) => name);
    this.availableSections = menuSections.filter(({ requiredRole }) => roles.includes(requiredRole));
  }

  setHasNoAccess(hasNoAccess) {
    this.hasNoAccess = hasNoAccess;
  }

  setSelectedTenant(tenant) {
    this.selectedTenant = tenant;
  }

  setServerTimeOffset({ date }) {
    this.serverTimeOffset = Date.now() - new Date(date).getTime();
  }

  setTenant(tenant) {
    this.setSelectedTenant(tenant);

    if (tenant?.uuid) {
      localStorage.setItem(TENANT_SETTING, tenant.uuid);
    }
  }

  async switchTenant(tenant) {
    resetFirebaseApp();
    this.setTenant(tenant);

    try {
      await this.fetch();
    } catch {}
  }
}

export default new Operator();
