import Vue from 'vue';
import {
  collection,
  deleteField,
  doc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  updateDoc,
  where,
} from 'firebase/firestore';
import { getFirestoreDb } from '@/application/firestore';
import operator from '@/application/operator';

const READ_LIMIT = 20;
let db;
let unsubscribeBlockedUsers;
let unsubscribeShadowBannedUsers;
let unsubscribeSearchUser;

const store = Vue.observable({
  blockedUsers: undefined,
  shadowBannedUsers: undefined,
  searchedUser: undefined,
});

const parseRecord = (document) => ({
  docRef: document.ref,
  id: document.id,
  ...document.data(),
});

const updateBlockedRecords = (snapshot) => {
  store.blockedUsers = snapshot.docs.map(parseRecord);
};

const updateShadowBannedRecords = (snapshot) => {
  store.shadowBannedUsers = snapshot.docs.map(parseRecord);
};

const load = async (tenantUuid) => {
  const blockedUsersQuery = query(
    collection(db, `tenants/${tenantUuid}/users`),
    orderBy('blockedAt'),
    where('blockedAt', '>=', 0),
    limit(READ_LIMIT),
  );

  await getDocs(blockedUsersQuery).then(updateBlockedRecords).catch(console.error);
  unsubscribeBlockedUsers = onSnapshot(blockedUsersQuery, updateBlockedRecords);

  const shadowBannedUsersQuery = query(
    collection(db, `tenants/${tenantUuid}/users`),
    orderBy('shadowBannedAt'),
    where('shadowBannedAt', '>=', 0),
    limit(READ_LIMIT),
  );

  await getDocs(shadowBannedUsersQuery).then(updateShadowBannedRecords).catch(console.error);
  unsubscribeShadowBannedUsers = onSnapshot(shadowBannedUsersQuery, updateShadowBannedRecords);
};

export const usersStoreGetters = {
  usersBlockedUsers: () => store.blockedUsers,
  usersShadowBannedUsers: () => store.shadowBannedUsers,
  usersSearchedUser: () => store.searchedUser,
};

export const usersStoreMutations = {
  blockUser: (user) => {
    const { id, profileId, username } = user;
    const docRef = doc(db, `tenants/${operator.selectedTenant.uuid}/users/${id}`);

    return updateDoc(docRef, {
      blockedAt: operator.getServerTime(),
      profile: { id: profileId, username },
    });
  },
  unblockUser: ({ id }) => {
    const docRef = doc(db, `tenants/${operator.selectedTenant.uuid}/users/${id}`);

    return updateDoc(docRef, {
      blockedAt: deleteField(),
      profile: deleteField(),
    });
  },
  shadowBanUser({ id }) {
    const docRef = doc(db, `tenants/${operator.selectedTenant.uuid}/users/${id}`);

    return updateDoc(docRef, {
      shadowBannedAt: operator.getServerTime(),
    });
  },
  unShadowBanUser({ id }) {
    const docRef = doc(db, `tenants/${operator.selectedTenant.uuid}/users/${id}`);

    return updateDoc(docRef, {
      shadowBannedAt: deleteField(),
    });
  },
};

export const searchUserById = async (id, queryField) => {
  unsubscribeSearchUser?.();
  const documentRef = doc(db, `tenants/${operator.selectedTenant.uuid}/users/${id}`);
  unsubscribeSearchUser = onSnapshot(documentRef, (snapshot) => {
    const searchedUser = parseRecord(snapshot);
    store.searchedUser = searchedUser[queryField] ? searchedUser : undefined;
  });
};

export const clearUserSearch = () => {
  unsubscribeSearchUser?.();
  store.searchedUser = undefined;
};

export const initUsersStore = async (tenantUuid, reset = false) => {
  if (reset) {
    db = undefined;
    store.blockedUsers = undefined;
    store.shadowBannedUsers = undefined;
    unsubscribeBlockedUsers?.();
    unsubscribeShadowBannedUsers?.();
  }

  if (!db) {
    db = await getFirestoreDb().catch(console.error);
  }

  if (!store.blockedUsers) {
    store.blockedUsers = [];
    store.shadowBannedUsers = [];
    load(tenantUuid);
  }
};
