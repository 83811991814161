<template>
  <div>
    <ui-tabs :tabs="tabs" class="ui-detail__tabs" />
    <component :is="$route.params.page" />
  </div>
</template>
<script>
import { UiTable, UiTabs } from 'redge-media-web-ui';
import operator from '@/application/operator';
import { initUsersStore, usersStoreGetters } from '@/application/users-store';
import { ADMIN } from '@/enums/role-name';
import { RoleMixin } from '@/mixins';
import Blocked from './blocked.vue';
import ShadowBanned from './shadow-banned.vue';

export default {
  components: {
    Blocked,
    ShadowBanned,
    UiTable,
    UiTabs,
  },
  mixins: [RoleMixin],
  data() {
    return {
      writeRole: ADMIN,
      tabs: [
        { label: 'COMMON_BLOCKED_USERS', page: 'blocked' },
        { label: 'COMMON_SHADOW_BANNED_USERS', page: 'shadow-banned' },
      ],
    };
  },
  computed: {
    ...usersStoreGetters,
  },
  mounted() {
    initUsersStore(operator.selectedTenant.uuid);
  },
};
</script>
