import { UiFormCheckbox, UiFormNumberInput, UiFormTextInput } from 'redge-media-web-ui';

export const textInputField = (field, label, isMandatory = false, disabled) => ({
  field,
  isMandatory,
  component: UiFormTextInput,
  componentOptions: {
    label,
    disabled,
  },
});

export const checkboxField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormCheckbox,
});

export const numberInputField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormNumberInput,
});
