import axios from 'axios';
import qs from 'qs';
import { AUTHENTICATION_REQUIRED, OPERATOR_INVALID_EMAIL_OR_PASSWORD, TENANT_SETTING } from '@/application/constants';
import EventBus from '@/application/event-bus';

const http = axios.create({
  baseURL: `${process.env.VUE_APP_BACKEND_URL}/api`,
  params: {},
  paramsSerializer: (params) => qs.stringify(params, { arrayFormat: 'brackets' }),
  withCredentials: true,
});

http.interceptors.request.use((config) => {
  try {
    config.params.tenantUuid = localStorage.getItem(TENANT_SETTING);
  } catch (e) {}

  return config;
});

http.interceptors.response.use(
  (response) => Promise.resolve(response),
  (error) => {
    const response = error?.response;

    if (response?.status === 403 && response?.data?.code !== OPERATOR_INVALID_EMAIL_OR_PASSWORD) {
      EventBus.emit(AUTHENTICATION_REQUIRED);
    }

    return Promise.reject(error);
  },
);

export default http;
