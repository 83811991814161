import router from '@/router';
import operator from '@/application/operator';

export default (isReplace = false) => {
  const route = router.currentRoute;
  const newRoute = {
    name: route.name ?? 'home',
    params: { ...route.params, tenantName: operator.selectedTenant.name },
    query: { ...route.query },
  };

  isReplace ? router.replace(newRoute).catch(() => {}) : router.push(newRoute).catch(() => {});
};
