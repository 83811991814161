import App from '@/application/app.vue';
import operator from '@/application/operator';

(async () => {
  try {
    await operator.fetch();
  } catch {}

  new App().$mount('#app');
})();
