import {
  collection,
  doc,
  endAt,
  endBefore,
  getDoc,
  getDocs,
  limit,
  onSnapshot,
  orderBy,
  query,
  startAt,
  where,
} from 'firebase/firestore';
import operator from '@/application/operator';
import { usersStoreGetters } from '@/application/users-store';
import * as MessageSearchType from '@/enums/message-search-type';

const MESSAGE_READ_LIMIT = 100;

export default {
  data() {
    return {
      initialRecords: [],
      newRecords: [],
      searchedRecords: [],
    };
  },
  computed: {
    ...usersStoreGetters,
    baseQueryList() {
      return [
        collection(this.document.ref, this.chatMessagesQueryPath),
        orderBy('createdAt', 'desc'),
        this.config.isForApprovalOnly
          ? where('shouldBeModerated', '==', true)
          : where('shouldBeModerated', 'in', [true, false]),
        this.config.isDeletedRecordsVisible ? null : where('deletedAt', '==', -1),
      ].filter(Boolean);
    },
    messages() {
      const isSearchingByDocumentId = this.keyword && this.searchType === MessageSearchType.DOCUMENT_ID;
      const records = isSearchingByDocumentId ? this.searchedRecords : [...this.newRecords, ...this.initialRecords];

      return records
        .map((record) => ({
          ...record,
          isUserBlocked: this.usersBlockedUsers.some(({ id }) => parseInt(id, 10) === record.user.id),
          isUserShadowBanned: this.usersShadowBannedUsers.some(({ id }) => parseInt(id, 10) === record.user.id),
        }))
        .filter(({ data }) => isSearchingByDocumentId || !this.keyword || data.includes(this.keyword));
    },
  },
  watch: {
    async keyword() {
      this.searchedRecords = [];

      if (this.searchType === MessageSearchType.DOCUMENT_ID) {
        const [documentId, replyId] = this.keyword.split('/');
        let path = `tenants/${operator.selectedTenant.uuid}/chats/${this.$route.params.id}/messages/${documentId}`;

        if (replyId) {
          path += `/replies/${replyId}`;
        }
        const documentRef = doc(this.db, path);
        const documentSnapshot = await getDoc(documentRef).catch(console.error);

        if (!documentSnapshot.exists()) {
          return;
        }

        this.searchedRecords = [this.createRecord(documentSnapshot)];
      }
    },
  },
  beforeDestroy() {
    this.unsubscribeAllRecords();
  },
  methods: {
    async load() {
      this.unsubscribeAllRecords();

      const initialQueryList = [
        ...this.baseQueryList,
        this.config.isReadLimitEnabled ? limit(MESSAGE_READ_LIMIT) : null,
        where('createdAt', '<', operator.getServerTime()),
      ].filter(Boolean);
      try {
        const initialQuery = query(...initialQueryList);
        const initialSnapshot = await getDocs(initialQuery);

        this.updateInitialRecords(initialSnapshot);
        this.unsubscribeInitialRecords = onSnapshot(initialQuery, this.updateInitialRecords);

        if (this.config.isLiveUpdate) {
          this.listenForNewRecords();
        }
      } catch (error) {
        console.error(error);
      }
    },
    listenForNewRecords() {
      const [firstRecord] = this.initialRecords;

      if (firstRecord) {
        const updateQuery = query(...this.baseQueryList, endBefore(firstRecord.createdAt));
        this.unsubscribeNewRecords = onSnapshot(updateQuery, this.updateNewRecords);
      }
    },
    updateInitialRecords(snapshot) {
      this.initialRecords = this.parseSnapshot(snapshot);
    },
    updateNewRecords(snapshot) {
      this.newRecords = this.parseSnapshot(snapshot);
    },
    disableListenForNewRecords() {
      this.unsubscribeNewRecords?.();

      const [firstRecord] = this.newRecords;
      const lastRecord = this.newRecords[this.newRecords.length - 1];

      if (firstRecord) {
        const updateQuery = query(...this.baseQueryList, startAt(firstRecord.createdAt), endAt(lastRecord.createdAt));

        this.unsubscribeNewRecords = onSnapshot(updateQuery, this.updateNewRecords);
      }
    },
    createRecord(document) {
      return {
        id: document.id,
        docRef: document.ref,
        ...document.data(),
      };
    },
    parseSnapshot({ docs }) {
      return docs.map((document) => this.createRecord(document));
    },
    unsubscribeAllRecords() {
      this.unsubscribeInitialRecords?.();
      this.unsubscribeNewRecords?.();
    },
  },
};
