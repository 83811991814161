<template>
  <div class="ui-form-header">
    <p class="ui-form-header__text" v-text="$translate(text)"></p>
    <i
      v-if="iconClass"
      v-tooltip="$translate(iconTooltipText)"
      :class="iconClass"
      class="ui-form-header__icon"
      @click="$emit('icon-click')"
    ></i>
  </div>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true,
    },
    iconClass: String,
    iconTooltipText: String,
  },
};
</script>
