import { dateFormat } from 'redge-media-web-ui';
import { ACTIONS, BUTTON_REMOVE } from '@/components/ui-table/columns';

export const createDateColumn = (field, headingLabel, width = 160) => ({
  field,
  width,
  isSortable: true,
  headingLabel,
  dataMapper: (record) => (record[field] !== -1 ? dateFormat(record[field]) : ''),
});

export const BLOCKED_AT = createDateColumn('blockedAt', 'COMMON_CREATED');
export const CREATED_AT = createDateColumn('createdAt', 'COMMON_CREATED');
export const DELETED_AT = createDateColumn('deletedAt', 'COMMON_DELETED');
export const LAST_LOGIN_DATE = createDateColumn('lastLoggedAt', 'COMMON_LAST_LOGIN_DATE', 180);

export const ACTIONS_OPERATOR = (isAllowed, removeRecordFunction, operatorId) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: record.id === operatorId ? [] : [BUTTON_REMOVE(() => removeRecordFunction(record))],
  });

  return ACTIONS(isAllowed, dataComponentPropsMapper);
};
