<template>
  <app-menu-base v-bind="{ config, sections }" />
</template>
<script>
import { AppMenu as AppMenuBase } from 'redge-media-web-ui';
import operator from '@/application/operator';

export default {
  components: {
    AppMenuBase,
  },
  computed: {
    config() {
      return {
        expandedSectionsKey: 'redge-media-chat-menu-expanded-sections',
        foldedKey: 'redge-media-chat-menu-folded',
        lockedKey: 'redge-media-chat-menu-locked',
      };
    },
    sections() {
      return operator.availableSections;
    },
  },
};
</script>
