import {
  ACTIONS, BUTTON_EDIT, BUTTON_REMOVE, CHECKBOX,
} from '@/components/ui-table/columns';

export const FORBIDDEN_WORDS_ACTIONS = (isAllowed, removeRecordMethod, editRecordMethod) => {
  const dataComponentPropsMapper = (record) => ({
    buttons: [BUTTON_EDIT(() => editRecordMethod(record)), BUTTON_REMOVE(() => removeRecordMethod(record))],
  });

  return ACTIONS(isAllowed, dataComponentPropsMapper);
};

export { CHECKBOX };
