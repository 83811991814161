<template>
  <div>
    <div class="ui-list__nav">
      <ui-search-input class="ui-list__search-input" :value="keyword" @input="keyword = $event" />
    </div>
    <ui-table v-bind="uiTableProps" />
  </div>
</template>
<script>
import { UiSearchInput, UiTable } from 'redge-media-web-ui';
import {
  clearUserSearch, searchUserById, usersStoreGetters, usersStoreMutations,
} from '@/application/users-store';
import { ADMIN } from '@/enums/role-name';
import { RoleMixin } from '@/mixins';
import * as Column from './columns';

export default {
  components: {
    UiSearchInput,
    UiTable,
  },
  mixins: [RoleMixin],
  data() {
    return {
      db: undefined,
      writeRole: ADMIN,
      records: [],
      searchedRecord: [],
      tabs: [
        { label: 'COMMON_BLOCKED_USERS', page: 'blocked' },
        { label: 'COMMON_SHADOW_BANNED_USERS', page: 'shadow-banned' },
      ],
      userType: 'usersBlockedUsers',
      keyword: '',
      queryField: 'blockedAt',
    };
  },
  computed: {
    ...usersStoreGetters,
    uiTableProps() {
      return {
        records: this.keyword ? this.searchedRecord : this[this.userType] || [],
        columns: [
          {
            field: 'id',
            headingLabel: 'CHAT_BLOCKED_USER_ID',
            width: 150,
          },
          {
            field: 'profile.id',
            headingLabel: 'CHAT_BLOCKED_USER_PROFILE_ID',
            width: 150,
          },
          {
            field: 'profile.username',
            headingLabel: 'CHAT_BLOCKED_USER_NICK',
            minWidth: 150,
          },
          Column.BLOCKED_AT,
          Column.BLOCKED_USERS_ACTIONS(this.isAllowed, this.unblockUser),
        ],
      };
    },
  },
  watch: {
    keyword() {
      this.searchedRecord = [];
      searchUserById(this.keyword, this.queryField);

      if (!this.keyword) {
        clearUserSearch();
      }
    },
    usersSearchedUser(record) {
      this.searchedRecord = record ? [record] : [];
    },
  },
  beforeDestroy() {
    clearUserSearch();
  },
  methods: {
    async unblockUser(user) {
      usersStoreMutations.unblockUser(user);
    },
  },
};
</script>
