<script>
export default {
  props: {
    dictUrl: {
      type: String,
    },
    immediate: {
      type: Boolean,
      default: false,
    },
    maxResults: {
      type: Number,
      default: 0, // 0 means return all results
    },
  },
  data() {
    return {
      fetching: false,
      records: [],
    };
  },
  watch: {
    $props: {
      handler: 'fetch',
      deep: true,
    },
  },
  created() {
    if (this.immediate) {
      this.fetch();
    }
  },
  methods: {
    async fetch({ keyword = '' } = {}) {
      this.fetching = true;

      this.records = await this.$http
        .get(this.dictUrl, { params: { keyword, size: this.maxResults } })
        .then(({ data }) => data.elements || data);

      this.fetching = false;
    },
  },
  render() {
    return this.$scopedSlots.default({
      fetching: this.fetching,
      fetch: this.fetch,
      records: this.records,
    });
  },
};
</script>
