import { UiDatePickerInput, UiFormCheckbox } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import {
  UiFormHeader, UiFormMultipleChoice, UiFormRegexp, UiFormTextInput,
} from '@/components';

export const EMAIL = (disabled) => ({
  field: 'email',
  isMandatory: true,
  component: UiFormTextInput,
  componentOptions: {
    label: 'COMMON_EMAIL',
    disabled,
  },
});

export const ROLES = {
  field: 'roles',
  isMandatory: true,
  component: UiFormMultipleChoice,
  componentOptions: {
    label: 'COMMON_ROLES',
    dictUrl: Urls.OPERATORS_ROLES,
    field: 'name',
    immediate: true,
  },
};

export const textInputField = (field, label, isMandatory = false, disabled) => ({
  field,
  isMandatory,
  component: UiFormTextInput,
  componentOptions: {
    label,
    disabled,
  },
});

export const regexpField = (field, label, disabled) => ({
  field,
  component: UiFormRegexp,
  componentOptions: {
    label,
    disabled,
  },
});

export const checkboxField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiFormCheckbox,
});

export const datePickerField = (...attrs) => ({
  ...textInputField(...attrs),
  component: UiDatePickerInput,
});

export const textHeader = (text) => ({
  component: UiFormHeader,
  componentOptions: {
    text,
  },
});

export const HEADER_BASIC_INFORMATION = textHeader('COMMON_BASIC_INFORMATION');
