<template>
  <div class="ui-form-regexp">
    <label class="ui-form-regexp__label" v-text="$translate(label)"></label>
    <div
      :class="{ 'ui-form-regexp__input-wrapper--has-error': errorMessage }"
      class="ui-form-regexp__input-wrapper"
      @click="focus"
    >
      <span>/</span>
      <div
        ref="inputPattern"
        class="ui-form-regexp__input"
        contenteditable="true"
        @input="emit"
      ></div>
      <span>/</span>
    </div>
    <div v-if="errorMessage" class="ui-form-item__error" v-text="errorMessage"></div>
    <ui-form-checkbox v-model="isCaseSensitive" label="Case sensitive" />
    <div class="ui-form-regexp__test-wrapper">
      <label class="ui-form-regexp__label" v-text="$translate('COMMON_TEST_STRING')"></label>
      <div class="ui-form-regexp__test-container">
        <div class="ui-form-regexp__test ui-form-regexp__test--formatted" v-html="formattedTestText"></div>
        <div
          ref="test"
          class="ui-form-regexp__test"
          contenteditable
          @input="onTestKeyDown"
        ></div>
      </div>
    </div>
  </div>
</template>
<script>
import { AppEvent, UiFormCheckbox } from 'redge-media-web-ui';
import { parseRegexp } from '@/utils';

export default {
  components: {
    UiFormCheckbox,
  },
  props: {
    label: String,
    value: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isCaseSensitive: true,
      pattern: undefined,
      testText: '',
      errorMessage: undefined,
    };
  },
  computed: {
    flags() {
      return this.isCaseSensitive ? 'gm' : 'gim';
    },
    formattedTestText() {
      try {
        const result = [...new Set(this.testText.match(new RegExp(this.pattern, this.flags)))].filter(Boolean);

        if (result.length > 0) {
          return result.reduce(
            (str, match) => str.replace(new RegExp(match, 'gm'), `<span>${match}</span>`),
            this.testText,
          );
        }
      } catch {}

      return this.testText;
    },
  },
  watch: {
    isCaseSensitive: 'emit',
  },
  async mounted() {
    await this.$nextTick();
    const { pattern, isCaseSensitive } = parseRegexp(this.value);
    this.$refs.inputPattern.innerText = pattern;
    this.pattern = pattern;
    this.isCaseSensitive = isCaseSensitive;
    this.focus();
  },
  methods: {
    focus() {
      this.$refs.inputPattern.focus();
    },
    onTestKeyDown() {
      const { test } = this.$refs;
      this.testText = test.innerHTML;
    },
    emit() {
      const { inputPattern } = this.$refs;
      this.pattern = inputPattern.innerText;
      try {
        /* eslint no-new: "off" */
        new RegExp(this.pattern, this.flags);
        this.errorMessage = undefined;
        this.$emit(AppEvent.INPUT, `/${this.pattern}/${this.flags}`);
      } catch (error) {
        this.errorMessage = error.message;
        this.$emit(AppEvent.INPUT, '');
      }
    },
  },
};
</script>
