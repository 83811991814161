import { ADMIN, READ } from '@/enums/role-name';

export default [
  {
    name: 'chat',
    label: 'COMMON_CHAT',
    requiredRole: READ,
    items: [
      {
        routeName: 'threads',
        icon: 'icon-list',
        label: 'COMMON_THREADS',
      },
      {
        routeName: 'users',
        icon: 'icon-user',
        label: 'COMMON_BLOCKED_USERS',
      },
      {
        routeName: 'forbidden-words',
        icon: 'icon-user',
        label: 'COMMON_FORBIDDEN_WORDS',
      },
    ],
  },
  {
    name: 'administration',
    label: 'COMMON_ADMINISTRATION',
    requiredRole: ADMIN,
    items: [
      {
        routeName: 'operators',
        icon: 'icon-operator',
        label: 'COMMON_OPERATORS',
      },
    ],
  },
];
