<template>
  <div v-if="document" class="page-threads-detail">
    <div class="ui-list__nav">
      <div class="page-threads-detail__search-wrapper">
        <ui-search-input class="ui-list__search-input" :value="keyword" @input="keyword = $event" />
        <select v-model="searchType" class="ui-select page-threads-detail__select">
          <option
            v-for="option in searchSelectOptions"
            :key="option"
            :value="option"
            v-text="$translate(`CHAT_MESSAGE_SEARCH_BY_${option}`)"
          ></option>
        </select>
      </div>
      <ui-checkbox v-model="config.isForApprovalOnly">
        <label class="form-item__label" v-text="$translate('CHAT_MESSAGE_SHOW_FOR_APPROVAL_ONLY')"></label>
      </ui-checkbox>
      <ui-checkbox v-model="config.isDeletedRecordsVisible">
        <label class="form-item__label" v-text="$translate('CHAT_MESSAGE_SHOW_DELETED')"></label>
      </ui-checkbox>
      <ui-checkbox v-model="config.isLiveUpdate">
        <label class="form-item__label" v-text="$translate('CHAT_MESSAGE_LIVE_UPDATE')"></label>
      </ui-checkbox>
      <ui-checkbox v-model="config.isReadLimitEnabled">
        <label
          class="form-item__label"
          v-text="`${$translate('CHAT_MESSAGE_READ_LIMIT')} (${MESSAGE_READ_LIMIT})`"
        ></label>
      </ui-checkbox>
    </div>

    <div class="ui-table page-threads-detail__messages">
      <ui-chat-message v-for="message in messages" :key="message.id" :message="message">
        <ui-chat-replies slot="replies" :config="config" :document="document" :message="message" />
      </ui-chat-message>
    </div>
  </div>
</template>
<script>
import { UiCheckbox, UiSearchInput } from 'redge-media-web-ui';
import operator from '@/application/operator';
import { getFirestoreDb } from '@/application/firestore';
import { initUsersStore, usersStoreGetters } from '@/application/users-store';
import { ADMIN } from '@/enums/role-name';
import * as MessageSearchType from '@/enums/message-search-type';
import { ChatMessagesMixin, RoleMixin } from '@/mixins';
import { UiChatMessage, UiChatReplies } from '@/components';

const MESSAGE_READ_LIMIT = 100;

export default {
  components: {
    UiChatMessage,
    UiChatReplies,
    UiCheckbox,
    UiSearchInput,
  },
  mixins: [ChatMessagesMixin, RoleMixin],
  props: {
    document: Object,
    documentRef: Object,
  },
  data() {
    return {
      db: undefined,
      writeRole: ADMIN,
      keyword: '',
      chatMessagesQueryPath: 'messages',
      MESSAGE_READ_LIMIT,
      config: {
        isDeletedRecordsVisible: false,
        isLiveUpdate: false,
        isForApprovalOnly: false,
        isReadLimitEnabled: true,
      },
      searchType: MessageSearchType.DOCUMENT_ID,
    };
  },
  computed: {
    ...usersStoreGetters,
    shouldModerate() {
      return this.document?.shouldModerate;
    },
    searchSelectOptions() {
      return Object.keys(MessageSearchType);
    },
  },
  watch: {
    'config.isDeletedRecordsVisible': 'load',
    'config.isForApprovalOnly': 'load',
    'config.isReadLimitEnabled': 'load',
    'config.isLiveUpdate': function toggleLiveUpdate() {
      if (this.config.isLiveUpdate) {
        this.listenForNewRecords();
      } else {
        this.disableListenForNewRecords();
      }
    },
  },
  async mounted() {
    this.db = await getFirestoreDb().catch(console.error);
    await initUsersStore(operator.selectedTenant.uuid);
    await this.load();
  },
};
</script>
