import { UiTableChatThreadLink } from '@/components';
import { CREATED_AT, DELETED_AT } from '@/pages/column';
import { CHECKBOX } from '@/components/ui-table/columns';

export const CHAT_THREAD_TITLE = {
  field: 'itemTitle',
  minWidth: 200,
  headingLabel: 'CHAT_THREAD_TITLE',
  dataComponent: UiTableChatThreadLink,
};

export { CREATED_AT, DELETED_AT, CHECKBOX };
