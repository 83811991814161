import { initializeApp, deleteApp } from 'firebase/app';
import { getAuth, signInWithCustomToken } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import operator from '@/application/operator';

const apiKey = process.env.VUE_APP_FIREBASE_API_KEY;
const authDomain = process.env.VUE_APP_FIREBASE_AUTH_DOMAIN || '';
const projectId = process.env.VUE_APP_FIREBASE_PROJECT_ID;

const firebaseAppInitialValues = {
  auth: null,
  db: null,
  initialized: false,
  instance: null,
};

const firebaseApp = { ...firebaseAppInitialValues };

const initialize = () => {
  firebaseApp.instance = initializeApp({ apiKey, authDomain, projectId });
  firebaseApp.auth = getAuth();
  firebaseApp.initialized = true;
};

const signInToFirestore = async (token) => signInWithCustomToken(firebaseApp.auth, token).catch(console.error);

export const initFirestore = async () => {
  if (!firebaseApp.initialized) {
    initialize();
  }

  if (!firebaseApp.auth.currentUser) {
    const token = await operator.fetchToken();
    await signInToFirestore(token);
  }

  if (!firebaseApp.db) {
    firebaseApp.db = getFirestore();
  }

  return Promise.resolve();
};

export const getFirestoreDb = async () => {
  await initFirestore();
  return firebaseApp.db;
};

export const resetFirebaseApp = () => {
  if (firebaseApp.instance) {
    deleteApp(firebaseApp.instance);
  }

  Object.assign(firebaseApp, firebaseAppInitialValues);
};
