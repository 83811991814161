<template>
  <div v-if="message.activeReplyCount || message.shouldModerateCount" class="ui-chat-replies">
    <div
      :class="{ 'ui-chat-replies__toggle--to-moderate': message.shouldModerateCount }"
      class="ui-chat-replies__toggle"
      @click="isRepliesVisible = !isRepliesVisible"
      v-text="repliesToggleText"
    ></div>
    <div v-if="isRepliesVisible" class="ui-chat-replies__messages">
      <ui-chat-message v-for="messageItem in messages" :key="messageItem.id" :message="messageItem" />
    </div>
  </div>
</template>
<script>
import { ChatMessagesMixin } from '@/mixins';
import { UiChatMessage } from '@/components';

export default {
  components: {
    UiChatMessage,
  },
  mixins: [ChatMessagesMixin],
  props: {
    document: Object,
    message: Object,
    config: Object,
  },
  data() {
    return {
      isRepliesVisible: false,
      isLiveUpdate: true,
      chatAddMessagesPath: 'replies',
    };
  },
  computed: {
    chatMessagesQueryPath() {
      return `messages/${this.message.id}/replies`;
    },
    repliesToggleText() {
      const shouldModerateCountText = this.message.shouldModerateCount > 0
        ? ` (${this.message.shouldModerateCount} ${this.$translate('CHAT_MESSAGE_SHOULD_MODERATE_COUNT')})`
        : '';

      return this.isRepliesVisible
        ? this.$translate('CHAT_MESSAGE_HIDE_REPLIES')
        : this.$translate('CHAT_MESSAGE_DISPLAY_REPLIES') + shouldModerateCountText;
    },
  },
  watch: {
    isRepliesVisible() {
      if (this.isRepliesVisible) {
        this.load();
      } else {
        this.unsubscribeAllRecords();
      }
    },
    'config.isDeletedRecordsVisible': 'update',
    'config.isForApprovalOnly': 'update',
    'config.isReadLimitEnabled': 'update',
    'config.isLiveUpdate': function toggleLiveUpdate() {
      if (this.isRepliesVisible) {
        if (this.config.isLiveUpdate) {
          this.listenForNewRecords();
        } else {
          this.disableListenForNewRecords();
        }
      }
    },
  },
  methods: {
    update() {
      if (this.isRepliesVisible) {
        this.load();
      }
    },
  },
};
</script>
