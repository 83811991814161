<template>
  <div v-if="!isLogged" class="login">
    <header class="login__header">
      <button
        v-for="language in $i18n.languages"
        :key="language"
        :class="{ 'login__header-language--active': $i18n.language === language }"
        class="login__header-language"
        @click="$i18n.setLanguage(language)"
        v-text="language.toUpperCase()"
      ></button>
    </header>

    <div class="login__content">
      <div class="login__container">
        <div class="login__logo-wrapper">
          <img src="/logo.svg" alt="Redge Media Chat" class="login__logo">
        </div>
        <p class="login__description" v-text="$translate('COMMON_LOGIN_PAGE_DESCRIPTION')"></p>
      </div>
      <form class="login__form">
        <h1 class="login__form-header" v-text="$translate('COMMON_LOG_IN')"></h1>
        <button
          type="button"
          class="login__form-submit login__form-submit--sso"
          @click.prevent="openAuthorizeBySSO"
          v-text="$translate('COMMON_LOG_IN_BY_SSO')"
        ></button>
      </form>
    </div>

    <footer class="login__footer">
      <span class="login__footer-text">
        <template v-if="appInfo">
          GUI: <em>{{ appInfo.guiDate }} ({{ appInfo.guiVersion }})</em> | API:
          <em>{{ appInfo.apiDate }} ({{ appInfo.apiVersion }})</em> |
        </template>
        &copy;
      </span>
      <a class="login__footer-link" href="https://www.redge.com"> Redge Technologies </a>
    </footer>
  </div>
</template>
<script>
import { focus } from 'redge-media-web-ui';
import Urls from '@/application/urls';
import operator from '@/application/operator';

export default {
  directives: {
    focus,
  },
  data() {
    return {
      appInfo: undefined,
    };
  },
  computed: {
    isLogged() {
      return operator.isLogged;
    },
  },
  methods: {
    openAuthorizeBySSO() {
      const requestUri = this.$route.query.request_uri || window.location.href;
      window.location.href = `${this.$http.defaults.baseURL}${Urls.LOGIN_SSO}?request_uri=${requestUri}`;
    },
  },
};
</script>
