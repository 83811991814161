import * as pages from '@/pages';

export default [
  {
    path: '/login',
    name: 'login',
    component: pages.Login,
    meta: {
      authenticationRequired: false,
    },
  },
  {
    path: '/:tenantName,t',
    name: 'home',
    redirect: { name: 'threads' },
  },
  {
    path: '/:tenantName,t/chat/threads',
    name: 'threads',
    component: pages.Threads,
  },
  {
    path: '/:tenantName,t/chat/threads/:id',
    redirect: {
      name: 'threads.detail',
      params: {
        page: 'metadata',
      },
    },
  },
  {
    path: '/:tenantName,t/chat/threads/:id/:page',
    name: 'threads.detail',
    component: pages.ThreadsDetail,
  },
  {
    path: '/:tenantName,t/chat/users',
    name: 'users',
    redirect: (to) => ({
      name: 'users.page',
      params: {
        page: 'blocked',
        tenantName: to.params.tenantName,
      },
    }),
  },
  {
    path: '/:tenantName,t/chat/users/:page',
    name: 'users.page',
    component: pages.BlockedUsers,
  },
  {
    path: '/:tenantName,t/chat/forbidden-words',
    name: 'forbidden-words',
    component: pages.ForbiddenWords,
  },
  {
    path: '/:tenantName,t/operators',
    name: 'operators',
    component: pages.Operators,
  },
  {
    path: '/:tenantName,t/operators/:id/:page',
    name: 'operators.detail',
    component: pages.OperatorsDetail,
  },
  {
    path: '*',
    redirect: { name: 'home' },
  },
];
