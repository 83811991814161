var render = function render(){var _vm=this,_c=_vm._self._c;return _c('app-header-base',_vm._b({on:{"change-tenant":_vm.changeTenant}},'app-header-base',{
    appData: _vm.appData,
    i18n: _vm.$i18n,
    info: _vm.info,
    logoutUrl: _vm.logoutUrl,
    operator: _vm.operator,
  },false))
}
var staticRenderFns = []

export { render, staticRenderFns }