<template>
  <div class="ui-chat-message">
    <div class="ui-chat-message__row">
      <ui-chat-message-avatar v-if="user" :user="user" />
      <span v-if="user.vip" class="ui-chat-message__vip" v-text="'VIP'"></span>
      <div
        :class="{
          'ui-chat-message__nickname--blocked': message.isUserBlocked,
          'ui-chat-message__nickname--banned': message.isUserShadowBanned,
        }"
        class="ui-chat-message__nickname"
        v-text="user.nickname"
      ></div>

      <div class="ui-chat-message__user-actions">
        <span
          v-tooltip="userBlockTooltipText"
          :class="{ 'ui-chat-message__icon-user-block--blocked': message.isUserBlocked }"
          class="icon-user ui-chat-message__icon ui-chat-message__icon-user-block"
          @click="message.isUserBlocked ? unblockUser(user) : blockUser(user)"
        >
        </span>
        <span
          v-tooltip="userShadowBanTooltipText"
          :class="{ 'ui-chat-message__icon-user-shadow-ban--banned': message.isUserShadowBanned }"
          class="icon-user ui-chat-message__icon ui-chat-message__icon-user-shadow-ban"
          @click="message.isUserShadowBanned ? unShadowBanUser(user) : shadowBanUser(user)"
        >
        </span>
      </div>
    </div>
    <div
      :class="{
        'ui-chat-message__data--should-be-moderated': message.shouldBeModerated,
        'ui-chat-message__data--deleted': message.deletedAt > 0,
        'ui-chat-message__data--shadow-banned': message.verified < 0,
      }"
      class="ui-chat-message__data"
    >
      <div class="ui-chat-message__data-info">
        <div class="ui-chat-message__created-at" v-text="createdAt"></div>
        <div class="ui-chat-message__message-actions">
          <span
            v-if="message.shouldBeModerated || message.verified < 0 || !message.verified"
            v-tooltip="$translate('HELP_ACCEPT')"
            class="ui-chat-message__icon icon-eye"
            @click="acceptMessage(message)"
          >
          </span>
          <span
            v-tooltip="messageBlockTooltipText"
            :class="[message.deletedAt < 0 ? 'icon-trash' : 'icon-unlocked']"
            class="icon-trash ui-chat-message__icon ui-chat-message__icon-message-block"
            @click="message.deletedAt < 0 ? blockMessage(message) : unBlockMessage(message)"
          >
          </span>
          <span
            v-tooltip="$translate('HELP_REMOVE_PERMANENTLY')"
            class="icon-trash ui-chat-message__icon ui-chat-message__icon-message-delete"
            @click="deleteMessage(message)"
          >
          </span>
        </div>
        <div class="ui-chat-message__id" v-text="`ID: ${message.id}`"></div>
      </div>
      <div class="ui-chat-message__text" v-html="messageText"></div>
    </div>
    <slot name="replies"></slot>
  </div>
</template>
<script>
import { deleteDoc, updateDoc } from 'firebase/firestore';
import { AppEvent, UiModalConfirm, dateFormat } from 'redge-media-web-ui';
import { usersStoreMutations } from '@/application/users-store';
import operator from '@/application/operator';
import UiChatMessageAvatar from './ui-chat-message-avatar.vue';

export default {
  components: {
    UiChatMessageAvatar,
  },
  props: {
    message: Object,
  },
  computed: {
    createdAt() {
      return dateFormat(this.message.createdAt);
    },
    user() {
      return this.message.user;
    },
    userBlockTooltipText() {
      return this.message.isUserBlocked ? this.$translate('HELP_UNBLOCK_USER') : this.$translate('HELP_BLOCK_USER');
    },
    userShadowBanTooltipText() {
      return this.message.isUserShadowBanned
        ? this.$translate('HELP_UNSHADOW_BAN_USER')
        : this.$translate('HELP_SHADOW_BAN_USER');
    },
    messageBlockTooltipText() {
      return this.message.deletedAt < 0 ? this.$translate('HELP_REMOVE') : this.$translate('HELP_UNDO_REMOVE');
    },
    messageText() {
      const { data, forbiddenWord } = this.message;
      const div = document.createElement('div');
      div.innerHTML = data;
      const text = div.textContent;

      return forbiddenWord ? text.replace(forbiddenWord, `<span>${forbiddenWord}</span>`) : text;
    },
  },
  methods: {
    ...usersStoreMutations,
    acceptMessage({ docRef }) {
      updateDoc(docRef, {
        shouldBeModerated: false,
        verified: true,
      });
    },
    blockMessage({ docRef }) {
      updateDoc(docRef, { deletedAt: operator.getServerTime() });
    },
    unBlockMessage({ docRef }) {
      updateDoc(docRef, { deletedAt: -1 });
    },
    deleteMessage({ docRef }) {
      const modal = this.$modal.create(UiModalConfirm, {
        title: this.$translate('COMMON_REMOVE'),
        confirmationText: this.$translate('COMMON_REMOVE_CONFIRMATION'),
      });

      modal.$on(AppEvent.SUCCESS, async () => {
        await deleteDoc(docRef);
        modal.close();
      });
    },
  },
};
</script>
