<template>
  <div v-if="document" class="page-threads-detail">
    <div class="ui-detail__header">
      <h1 class="ui-detail__header-text" v-text="headerText"></h1>
      <ui-switch :value="document.active" class="ui-detail__ui-switch" @input="toggleActive" />
    </div>
    <ui-tabs :tabs="tabs" class="ui-detail__tabs" />
    <component :is="$route.params.page" :document="document" :document-ref="documentRef" />
  </div>
</template>
<script>
import { doc, onSnapshot, updateDoc } from 'firebase/firestore';
import { UiSwitch, UiTabs } from 'redge-media-web-ui';
import { getFirestoreDb } from '@/application/firestore';
import operator from '@/application/operator';
import { ADMIN } from '@/enums/role-name';
import { RoleMixin } from '@/mixins';
import Messages from './messages.vue';
import Metadata from './metadata.vue';

export default {
  components: {
    Messages,
    Metadata,
    UiSwitch,
    UiTabs,
  },
  mixins: [RoleMixin],
  data() {
    return {
      tabs: [
        { label: 'COMMON_METADATA', page: 'metadata' },
        { label: 'COMMON_MESSAGES', page: 'messages' },
      ],
      documentRef: undefined,
      document: undefined,
      db: undefined,
      writeRole: ADMIN,
    };
  },
  computed: {
    headerText() {
      return this.document && `${this.document.itemTitle} (${this.document.name})`;
    },
  },
  async mounted() {
    this.db = await getFirestoreDb();

    this.documentRef = doc(this.db, `tenants/${operator.selectedTenant.uuid}/chats/${this.$route.params.id}`);
    this.unsubscribeDocument = onSnapshot(this.documentRef, this.updateDocument);
  },
  beforeDestroy() {
    this.unsubscribeDocument?.();
  },
  methods: {
    updateDocument(document) {
      this.document = document.data();
      this.document.ref = document.ref;
    },
    toggleActive(isActive) {
      updateDoc(this.documentRef, {
        active: isActive,
      });
    },
  },
};
</script>
