<template>
  <div class="ui-detail">
    <div class="ui-detail__header">
      <h1 v-if="model" class="ui-detail__header-text" v-text="model.email"></h1>
      <p v-if="model" class="ui-detail__header-dates" v-text="modelDates"></p>
    </div>
    <ui-tabs :tabs="tabs" class="ui-detail__tabs" />
    <component :is="$route.params.page" :id="$route.params.id" @model="onModel" />
  </div>
</template>
<script>
import { UiTabs } from 'redge-media-web-ui';
import Metadata from './metadata.vue';

export default {
  components: {
    UiTabs,
    Metadata,
  },
  data() {
    return {
      tabs: [{ label: 'COMMON_METADATA', page: 'metadata' }],
      model: undefined,
    };
  },
  computed: {
    modelDates() {
      const createdDate = `${this.$translate('COMMON_CREATED')}: ${this.$dateFormat(this.model.createdAt)}`;
      const modifiedDate = `${this.$translate('COMMON_MODIFIED')}: ${this.$dateFormat(this.model.modifiedAt)}`;
      return `${createdDate}, ${modifiedDate}`;
    },
  },
  methods: {
    onModel(model) {
      this.model = { ...model };
    },
  },
};
</script>
