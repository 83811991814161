<script>
import { usersStoreMutations } from '@/application/users-store';
import UiBlockedUsers from './blocked.vue';

export default {
  extends: UiBlockedUsers,
  data() {
    return {
      userType: 'usersShadowBannedUsers',
      queryField: 'shadowBannedAt',
    };
  },
  methods: {
    async unblockUser(user) {
      usersStoreMutations.unShadowBanUser(user);
    },
  },
};
</script>
