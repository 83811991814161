<template>
  <router-link
    :to="{ name: 'threads.detail', params: { id: record.name, page: 'metadata' } }"
    v-text="record.itemTitle"
  />
</template>
<script>
export default {
  props: {
    record: {
      type: Object,
      required: true,
    },
  },
};
</script>
